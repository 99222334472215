import {DatePickerTab} from "./tacho_shared";

export default class VehicleOverspeedTab extends DatePickerTab
{
    renderContent($table, content) {
        const $tbody = $table.children('tbody')
        $tbody.empty()

        for (const index in content.data) {
            const row = content.data[index]
            const $row = $('<tr>')

            const $startTD = $('<td>'),
                  $endTD = $('<td>'),
                  $speedTD = $('<td>'),
                  $averageTD = $('<td>'),
                  $driverTD = $('<td>');

            const $column = $('<td>').text()
            $startTD.text(row.start_time)
            $endTD.text(row.end_time)
            $speedTD.text(row.max_speed)
            $averageTD.text(row.average_speed)
            $driverTD.text(row.driver_name)

            $row.append($startTD)
            $row.append($endTD)
            $row.append($speedTD)
            $row.append($averageTD)
            $row.append($driverTD)

            $tbody.append($row)
        }
    }
}