import {DatePickerTab} from "./tacho_shared";
import {DateTime} from "luxon";

$.fn.serializeObject = function(){ let d={}; $(this).serializeArray().forEach(r=>d[r.name]=r.value); return d;}

export default class DriverInfringementsTab extends DatePickerTab
{
    constructor ($tab) {
        super($tab)
        // this.addLetterModal = addLetterModal.bind(this)
    }

    renderInfringementsTable($table, content)
    {
        const infringementsTab = this;
        const $tbody = $table.children('tbody')
        $tbody.empty();

        if (content.data.length === 0) {
            const $row = $('<tr>');
            const $col = $('<td>').html('No Infringements').attr('colspan', 10);
            $row.append($col);
            $tbody.append($row);
        } else {
            for (const index in content.data) {
                const row = content.data[index];
                const $row = $('<tr>');

                $row.data('event-time', moment(row.event_start).format('YYYYMMDDHHmmss'));

                for (const k in row) {
                    const data = k === 'event_start' ? moment(row[k]).format(formatDatetime) : row[k]
                    const $column = $(`<td class="col_${k}">`).html(data);
                    $row.append($column);
                }

                $tbody.append($row);
            }
            // attach new click event for button rather than row
            $tbody.find('.btn-view-infringement').on('click', function(e){
                const timestamp = $(e.currentTarget).closest('tr').data('eventTime');
                const dmy = moment(timestamp, 'YYYYMMDDHHmmss').format('DD-MM-YYYY');
                window.location = `${app.CACHE.URL_ADMIN}tco_drv_activity/${app.URI[2]}/${dmy}`;
            });

            // create letter
            // $tbody.find('.btn-letter-add').on('click', function(){
            //     infringementsTab.addLetterModal('driver_infringements_tab', $(this), 'day');
            // });
        }
    }

    jumpToActivityInfringementRow(e)
    {
        const $activityTab = app.DOM.tabs.filter('.tab-activity');
        const $activityTabHeader = app.DOM.form_tab_a.filter('.tab-activity');
        const $clickedRow = $(e.target).parents('tr');
        const $tab = $clickedRow.parents('.tab');
        const targetRow = 'I' + $clickedRow.data('event-time');

        if ($tab[0] === $activityTab[0]) {
            document.getElementById(targetRow).scrollIntoView();
            window.scrollBy(0, -75);
        } else {
            const targetDate = moment($clickedRow.data('event-time'), 'YYYYMMDDHHmmss', true);
            let startDate = targetDate.isValid() ? targetDate : moment($tab.find('#date_start').val()),
                endDate = targetDate.isValid() ? targetDate : moment($tab.find('#date_end').val());

            $activityTab.find('.startDatePicker').datepicker('setDate', startDate.toDate());
            $activityTab.find('.endDatePicker').datepicker('setDate', endDate.toDate());
            $activityTab.find('.startDatePicker').val(startDate.format('DD-MM-YYYY'));
            $activityTab.find('.endDatePicker').val(endDate.format('DD-MM-YYYY'));
            $activityTab.find('#date_start').val(startDate.format('YYYY-MM-DD'));
            $activityTab.find('#date_end').val(endDate.format('YYYY-MM-DD'));

            const DriverTabObject= this;

            window.$tabs.get('DriverActivityTab').triggerRemoteRebuildTables(function () {

                const targetDate = DateTime.fromFormat($clickedRow.data('event-time').substring(0, 8), 'yyyyMMdd');
                $(`table#activity_${targetDate.toISODate()}`).parents('tr').prev().find('a.btn-activity-table-toggle').click();

                $activityTabHeader.removeClass('tab-refresh-on-click')
                $activityTabHeader.click();
                $activityTabHeader.addClass('tab-refresh-on-click')
                
                document.getElementById(targetRow).scrollIntoView();
                window.scrollBy(0, -75);

                DriverTabObject.updateUrlFromClickedTab($activityTab);
            });
        }

        e.stopPropagation();
    }
}
