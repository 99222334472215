import VehicleOverviewTab from './form/_vehicle_overview_tab';
import VehicleCalendarTab from './form/_vehicle_calendar_tab';
import VehicleOverspeedTab from './form/_vehicle_overspeed_tab';
import VehicleInfringementsTab from './form/_vehicle_infringement_tab';
import VehicleActivityTab from './form/_vehicle_activity_tab';
import VehicleUploadsTab from "./form/_vehicle_uploads_tab";

import DriverActivityTab from './form/_driver_activity_tab';
import DriverOverviewTab from './form/_driver_overview_tab';
import DriverCalendarTab from './form/_driver_calendar_tab';
import DriverInfringementsTab from './form/_driver_infringement_tab';
import DriverUploadsTab from './form/_driver_uploads_tab';
import DriverCardHistoryTab from './form/_driver_card_history_tab';
import DriverRestTab from "./form/_driver_rest_tab";
import DriverWorkingTimeCalendar from "./form/_driver_wtd_calendar_tab";
import DriverLettersTab from "./form/_driver_letter_tab";
import DriverMissingMileageTab from "./form/_driver_missing_mileage_tab";

const classMap = new Map([
    ['VehicleCalendarTab', VehicleCalendarTab],
    ['VehicleOverviewTab', VehicleOverviewTab],
    ['VehicleActivityTab', VehicleActivityTab],
    ['VehicleOverspeedTab', VehicleOverspeedTab],
    ['VehicleInfringementsTab', VehicleInfringementsTab],
    ['VehicleUploadsTab', VehicleUploadsTab],
    ['DriverCalendarTab', DriverCalendarTab],
    ['DriverOverviewTab', DriverOverviewTab],
    ['DriverActivityTab', DriverActivityTab],
    ['DriverInfringementsTab', DriverInfringementsTab],
    ['DriverLettersTab', DriverLettersTab],
    ['DriverRestTab', DriverRestTab],
    ['DriverWorkingTimeTab', DriverWorkingTimeCalendar],
    ['DriverUploadsTab', DriverUploadsTab],
    ['DriverCardHistoryTab', DriverCardHistoryTab],
    ['DriverMissingMileageTab', DriverMissingMileageTab]
]);

export default function instantiator(name, ...args) {
    if (classMap.has(name)) {
        return new (classMap.get(name))(...args);
    }

    return null;
}