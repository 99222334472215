import {BaseTab} from "./tacho_shared";

export default class DriverCardHistoryTab extends BaseTab
{
    renderContent($table, content) {
        const $tbody = $table.children('tbody')
        $tbody.empty();

        for (const index in content.data) {
            const row = content.data[index];
            const $row = $('<tr>');

            for (const k in row) {
                const $column = $('<td>').html(row[k]);
                $row.append($column);
            }
            $tbody.append($row);
        }
    }
}