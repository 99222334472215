import {DatePickerTab} from "./tacho_shared";
import { DateTime } from 'luxon'
import { merge } from 'lodash'
import {getSVGForIcon, manifest} from "../shared/common";

export default class VehicleActivityTab extends DatePickerTab {
    #cumulatives;

    constructor($tab) {
        super($tab);

        $tab.find('#slot_filter').change(this.#updateSlotFilter.bind(this))
    }

    jumpToActivityInfringementRow(e) {
        const $activityTab = this._$hostingTab;
        const $activityTabHeader = app.DOM.form_tab_a.filter('.tab-activity');
        const $clickedRow = $(e.target).parent('tr');
        const $tab = $clickedRow.parents('.tab');
        const targetRow = 'I' + $clickedRow.data('event-time');

        if ($tab[0] === $activityTab[0]) {
            document.getElementById(targetRow).scrollIntoView();
            window.scrollBy(0, -75);
        } else {
            let startDate = moment($tab.find('#date_start').val()),
                endDate = moment($tab.find('#date_end').val());

            $activityTab.find('.startDatePicker').datepicker('setDate', startDate.toDate());
            $activityTab.find('.endDatePicker').datepicker('setDate', endDate.toDate());
            $activityTab.find('.startDatePicker').val(startDate.format('DD-MM-YYYY'));
            $activityTab.find('.endDatePicker').val(endDate.format('DD-MM-YYYY'));
            $activityTab.find('#date_start').val(startDate.format('YYYY-MM-DD'));
            $activityTab.find('#date_end').val(endDate.format('YYYY-MM-DD'));
            $activityTabHeader.trigger('click');

            this.rebuildTables(function () {
                document.getElementById(targetRow).scrollIntoView();
                window.scrollBy(0, -75);
            });

            this.updateUrlFromClickedTab();
        }

        e.stopPropagation();
    }

    renderActivityView ($table, content) {
        const format = merge({}, DateTime.TIME_24_SIMPLE, DateTime.DATE_SHORT)

        const shifts = content.data.shifts;
        const infringements = content.data.infringements;
        let $tbodies = {};

        for (const shift_id in shifts) {
            const shift = shifts[shift_id]
            const start_time = DateTime.fromISO(shift.date_start).toUTC()
            const end_time = DateTime.fromISO(shift.date_end).toUTC()
            const start_time_str = start_time.toLocaleString(format)
            const end_time_str = end_time.toLocaleString(format)
            const $shiftBlock = $('<TBODY>').attr('id', 'S' + start_time.toFormat('yyyyMMddHHmmss'))

            const $shiftStartRow = $('<TR>').addClass('Card Inserted').attr('id', start_time.toFormat('yyyyMMddHHmmss'));
            const initialActivity = shift.activities[0];
            let [
                driving_status,
                card_status,
                vehicle_activity,
                date_activity_change,
            ] = initialActivity;
            const $icon = getSVGForIcon([
              'Card',
              shift.slot === 'Main' ? 'DriverSlot' : 'CodriverSlot',
              vehicle_activity
            ]);

            const $eventTD = $('<td>').text(`Card Inserted: Set to ${vehicle_activity}`);
            const $iconTD = $('<td>').append($icon);
            const $detailsTD = $('<td>').html(`${shift.driver} in ${shift.slot} slot`);
            const $timeTD = $('<td>').text(start_time_str);
            /*
            const $cell = $('<TD>').attr('colspan', 4)
            const $shiftStartCell = $cell.clone().text(`${shift.driver} in ${shift.slot} slot, started at ${start_time_str}. Mileage ${shift.odo_start}`)
            */
            const $shiftEndCell = $('<TD>').attr('colspan', 4).text(`Ended at ${end_time_str}. Mileage ${shift.odo_end}`)

            $shiftBlock.append($shiftStartRow.append($timeTD, $iconTD, $eventTD, $detailsTD))
            this.insertRowIdIntoActivityRows(start_time.setZone('UTC').toFormat('yyyyMMddHHmmss'))

            let previousRow = shift.activities.shift();

            for(const i in shift.activities) {
                const row = shift.activities[i];
                const time = DateTime.fromISO(row[3]).toUTC()

                const $row = $('<tr>');
                $row.attr('id', time.toFormat('yyyyMMddHHmmss'));

                const [event, details, icons] = this.calculateEvent(row, previousRow);
                const $icon = getSVGForIcon(icons);
                const $eventTD = $('<td>').text(event);
                const $iconTD = $('<td>').append($icon);
                const $detailsTD = $('<td>').html(details);
                const $timeTD = $('<td>').text(time.toFormat('HH:mm'));
                $row.addClass(event);
                $row.append($timeTD);
                $row.append($iconTD);
                $row.append($eventTD);
                $row.append($detailsTD);

                $shiftBlock.append($row);
                previousRow = row;
                this.insertRowIdIntoActivityRows(time.setZone('UTC').toFormat('yyyyMMddHHmmss'))
            }

            const $shiftEndRow = $('<TR>')
            $shiftBlock.append($shiftEndRow.append($shiftEndCell))

            $tbodies[start_time.toFormat('yyyyMMddHHSmmss')] = $shiftBlock;
        }

        $table.find('tbody').remove();
        $tbodies = Object.fromEntries(Object.entries($tbodies).sort());

        for (const k in $tbodies) {
            $table.append($tbodies[k]);
        }

        for (const index in infringements) {
            const row = infringements[index];
            const time = DateTime.fromISO(row.date_infringement).toUTC()
            const rowID = 'I' + time.setZone('UTC').toFormat('yyyyMMddHHmmss');
            const $row = $('<tr>').attr('id', rowID).addClass('infringement').addClass(row.infringement_code);
            const $cell = $('<td>').html(`<i class="fas fa-exclamation-triangle"></i> ${row.message}`).attr('colspan', 4);

            $row.append($cell);

            const candidateKeys = this._activityRowKeys.filter(this._dropActivityRowKeysAfterDate(time));
            let key
            if (candidateKeys.length === 0) {
                key = this._activityRowKeys[0]
            } else {
                key = candidateKeys.pop()
            }
            $row.insertBefore('tr#' + key)

            this.insertRowIdIntoActivityRows(rowID)
        }

        const dailyChecks = content.data.daily_checks
        for (const row of dailyChecks) {
            const time = DateTime.fromISO(row.date_start).toUTC();
            const endTime = DateTime.fromISO(row.date_end).toUTC();
            const duration = endTime.diff(time).rescale().toFormat("h'h' m'm'");
            const rowID = 'D' + time.toFormat('yyyyMMddHHmmss');
            const $row = $('<tr>').attr('id', rowID).addClass('daily_check');

            const latLng = ( row.lat ) ? `<a href="https://openstreetmap.org/?mlat=${row.lat}&mlon=${row.lng}#map=17/${row.lat}/${row.lng}" target="_blank" title="View on map"><i class="green fas fa-location"></i></a>` : '';

            let dailyCheckHTML = `
            <td>${time.toLocaleString(DateTime.TIME_SIMPLE)}</td>
            <td><a href="${app.CACHE.URL_ADMIN}rep/${row.id}<i title="Daily check" class="fa-light fa-file-alt"></i></a>${latLng}</td>
            <td><a href="${app.CACHE.URL_ADMIN}rep/${row.id}">Daily Check #${row.id}</a> by ${row.driver}</td>
            <td>${duration} (Mileage: ${row.mileage})</td>`;

            $row.html(dailyCheckHTML);

            const candidateKeys = this._activityRowKeys.filter(this._dropActivityRowKeysAfterDate(time));
            let key
            if (candidateKeys.length === 0) {
                key = this._activityRowKeys.filter(this._dropActivityRowKeysBeforeDate(time)).shift();
                $row.insertBefore('tr#' + key)
            } else {
                key = candidateKeys.pop()
                if (key.substring(0, 8) != time.toFormat('yyyyMMdd')) {
                    key = this._activityRowKeys.filter(this._dropActivityRowKeysBeforeDate(time)).shift();
                    $row.insertBefore('tr#' + key)
                } else {
                    $row.insertAfter('tr#' + key)
                }
            }

            this.insertRowIdIntoActivityRows(rowID)
        }

        const missingMileageComments = content.data.missing_mileage
        for (const row of missingMileageComments) {
            const comment = row.comment,
              comment_user = row.comment_user,
              startDate = DateTime.fromISO(row.start_date).toUTC(),
              endDate = DateTime.fromISO(row.end_date).toUTC(),
              commentDate = DateTime.fromISO(row.comment_date).toUTC(),
              missing_mileage = row.end_mileage - row.start_mileage

            const rowID = 'M' + startDate.toFormat('yyyyMMddHHmmss');
            const $row = $('<tr>').attr('id', rowID).addClass('missing_mileage');
            const $icons = [$('<img>').attr({
                height: 16,
                width: 16,
                title: 'Odometer Reading',
                src: manifest('img/sprites/Mileage.svg')
            })]
            let message, addEditDeleteButtons
            if (comment === null) {
                message = 'Unaccounted Mileage'
                $icons.push($('<img>').attr({
                    height: 16,
                    width: 16,
                    title: 'No explanation for mileage',
                    src: manifest('img/sprites/Unknown.svg')
                }))
                addEditDeleteButtons = `<a href="" class="btn-remodal" title="Add comment" data-micromodal-trigger="tacho_mileage_add_comment" data-gap-id="${row.id}"><i class="btn-comment-add fa-solid fa-comment-plus"></i></a>`
            } else {
                message = `Accounted Mileage - <span id="comment_${row.comment_id}">${comment}</span> (${comment_user} @ ${commentDate.toLocaleString(format)})`
                addEditDeleteButtons = `
                    <a href="" class="btn-remodal" title="Edit comment" data-micromodal-trigger="tacho_mileage_edit_comment" data-comment-id="${row.comment_id}" data-type="mileageCommentEdit"><i class="btn-comment-edit fa-solid blue fa-comment-pen"></i></a>
                    <a href="" class="btn-remodal" title="Delete comment" data-micromodal-trigger="tacho_mileage_delete_comment" data-comment-id="${row.comment_id}" data-type="mileageCommentDelete"><i class="btn-comment-delete fa fa-trash red"></i></a>
                `
            }

            const HTML = `
            <td>${startDate.toLocaleString(DateTime.TIME_SIMPLE)}</td>
            <td></td>
            <td>${message}</td>
            <td>
                ${missing_mileage}
                ${addEditDeleteButtons}
            </td>`;

            $row.html(HTML);
            $row.find('td:nth-of-type(2)').append(...$icons)

            const selector = `tbody[id^=S${startDate.toFormat('yyyyMMdd')}]>tr`;
            const lastRowOfBody = $(selector).last();
            lastRowOfBody.after($row)
        }

        // add date <h3> to each day
        let index = 0;
        const tbodyIndexes= Object.keys($tbodies);

        $.each($tbodies, (k,v) => {
            let ymd = k.slice(0,8);
            let ymdPrev = tbodyIndexes[index-1]?.slice(0,8);

            // add date
            if( !ymdPrev || ymd !== ymdPrev ) {
                $tbodies[k].prepend(`<tr><th class="heading" colspan="4"><h3>${moment(ymd, 'YYYYMMDD', true).format('ddd, Do MMM YYYY')}</h3></th></tr>`);
            }

            index++;
        });

        this.configureModals($('table.tacho_activity'));
    }

    dropActivityRowKeysAfterDate(date) {
        return function (value) {
            if (value.substring(0, 1) === 'I') {
                value = value.substring(1);
            }

            return value <= date.format('YYYYMMDDHHmmss');
        }
    }

    insertDateRow(row, $tbody, mileageRecords) {
        let [
            unit_card_slot,
            driving_status,
            card_status,
            vehicle_activity,
            date_activity_change
        ] = row;

        const $row = $('<tr>');
        const time = moment(date_activity_change);
        $row.attr('id', time.format('YYYYMMDD'));
        const mileage = mileageRecords[time.format('YYYY-MM-DD')] || 'Unknown';
        const $eventTD = $('<td>').text(`${time.format('Do MMM, YYYY')}, Mileage ${mileage}`).attr('colspan', 4);
        $row.append($eventTD);
        $tbody.append($row);
    }

    calculateEvent(row, previousRow) {
        let [
            driving_status,
            card_status,
            vehicle_activity,
            date_activity_change,
        ] = row;

        if (previousRow === null) {
            return [
                vehicle_activity,
                '-',
                vehicle_activity
            ]
        }

        let [
            previous_driving_status,
            previous_card_status,
            previous_vehicle_activity,
            previous_date_activity_change,
        ] = previousRow;

        if (card_status !== previous_card_status) {
            // Card Insertion/Removal
            const icons = ['Card'];
            if (card_status === 'Inserted') {
                icons.push(vehicle_activity);
                return [`Card Inserted`, ``, icons];
            } else {
                return ['Card Removed', '', icons];
            }
        }

        const startDate = moment(previous_date_activity_change);
        const endDate = moment(date_activity_change);
        const duration = endDate.diff(startDate, 'minutes');

        return [
            vehicle_activity,
            this.format_duration(duration),
            vehicle_activity
        ];
    }

    format_duration(minutes)
    {
        const hours = Number.parseInt(minutes / 60);
        minutes = minutes % 60;
        return `${hours}h ${minutes}m`;
    }

    daily_summary()
    {
        return [
            `Driving: ${this.format_duration(this.#cumulatives.Driving)}`,
            `Working: ${this.format_duration(this.#cumulatives.Working)}`,
            `Available: ${this.format_duration(this.#cumulatives.Available)}`,
            `Rest: ${this.format_duration(this.#cumulatives.Rest)}`,
        ].join(', ');
    }

    #updateSlotFilter(e) {
        this.rebuildTables();
    }
}