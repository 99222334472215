export default class LibraryFunctions {

    combineParams(paramArray) {
        const params = [], returnParams = [];
        for (const key in paramArray) {
            let k;
            if (key.endsWith('[]')) {
                k = key.slice(0, -2);
            } else {
                k = key;
            }

            if (!params[k]) {
                params[k] = [];
            }

            params[k].push(paramArray[key]);
        }

        for (const k in params) {
            if (k.endsWith('_original')) {
                continue;
            }

            const v = params[k];
            returnParams.push(`${k}:${v.join(',')}`)
        }

        return returnParams.join('|');
    }
}
