import {BaseTab} from "./tacho_shared";
import {DateTime} from "luxon";
import CalendarTab from "./_calendar_tab";
import {AjaxSync} from "../shared/ajax_utilities";

export default class DriverWorkingTimeCalendar extends CalendarTab
{
    #driverId;

    constructor($tab) {
        super($tab);

        this.#driverId = $('input#driver_id').val();
    }

    rebuild_calendar(baseDate) {
        const $table = this._$hostingTab.find('table#tacho_wtd_calendar');
        let sourceURL = $table.data('render-url') + '/' + this.combineParams({
            'date': baseDate,
            'driver_id': this.#driverId,
        });

        $table.addClass('loading');
        AjaxSync({ url: sourceURL, method: 'GET' }, {
            done: (content) => {
                $('table#tacho_wtd_calendar').replaceWith(content);
                this.connectHandlers();
                this.fetchData(baseDate)
            }
        });
    }

    fetchData(baseDate)
    {
        const $table = this._$hostingTab.find('table#tacho_wtd_calendar');
        let sourceURL = $table.data('source-url');
        if (sourceURL === null) {
            return;
        }

        baseDate = DateTime.fromISO(baseDate);
        const parameters = this.combineParams({
            'month': baseDate.toFormat('yyyy-MM'),
            'driver_id': this.#driverId,
        });

        AjaxSync({
            url: `${sourceURL}/${parameters}`,
            method: 'GET',
            dataType: 'json',
        }, {
            done: (content) => this.renderCalendar($table, content),
        });

        this._addonElements($table, parameters);
    }

    renderCalendar($table, content) {
        $table.find('li.Working').hide();
        for(const f in content.data) {

            const data = content.data[f];


            const $cell = $table.find(`td#${f}`);
            if ($cell.length === 0) {
                continue;
            }

            $cell.addClass('has-data')
                 .find(`li.Working`)
                 .html(`<span>${this._format_duration(data.time / 60)}</span>`)
                 .show();

            // Weekly hours must never exceed 60h
            if (data.time > 60 * 3600 || data.Infringement) {
                $cell.addClass('has-infringement');
            }

            $cell.off('click').on('click', this.displayDriverActivityTab.bind(this))
        }

        this.calenderRendered($table);
    }

    triggerRemoteRebuildTables(tabName)
    {
        if (!tabName) {
            tabName = this._$hostingTab[0].className.split(' ')[1].substring(4);
        }
        tabName = (tabName.substring(0, 1).toUpperCase() + tabName.substring(1))
            .replace(/-([a-z])/, (m, p1) => p1.toUpperCase());

        super.triggerRemoteRebuildTables(`Driver${tabName}Tab`)
    }

    connectHandlers() {
        super.connectHandlers();
    }
}