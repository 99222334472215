import {BaseTab} from "./tacho_shared";
import {DateTime, Duration} from "luxon";
import CalendarTab from "./_calendar_tab";
import {AjaxSync} from "../shared/ajax_utilities";

export default class DriverRestTab extends CalendarTab
{
    #driverId;

    constructor($tab) {
        super($tab);

        this.#driverId = $('input#driver_id').val();
    }

    rebuild_calendar(baseDate) {
        const $table = this._$hostingTab.find('table#tacho_rest_calendar');
        let sourceURL = $table.data('render-url') + '/' + this.combineParams({
            'date': baseDate,
            'driver_id': this.#driverId,
        });

        $table.addClass('loading');
        AjaxSync({ url: sourceURL, method: 'GET' }, {
            done: (content) => {
                $('table#tacho_rest_calendar').replaceWith(content);
                this.connectHandlers();
                this.fetchData();
            }
        });
    }

    fetchData()
    {
        const $table = this._$hostingTab.find('table#tacho_rest_calendar');
        let sourceURL = $table.data('source-url');
        if (sourceURL === null) {
            return;
        }

        let baseDate = DateTime.fromISO(this._$hostingTab.find('input#base_date').val());
        if (!baseDate.isValid) {
            return;
        }

        const parameters = this.combineParams({
            'month': baseDate.toFormat('yyyy-MM'),
            'driver_id': this.#driverId,
        });

        AjaxSync({
            url: `${sourceURL}/${parameters}`,
            method: 'GET',
            dataType: 'json',
        }, {
            done: (content) => this.renderCalendar($table, content),
        });

        this._addonElements($table, parameters);
    }

    renderCalendar($table, content) {
        $table.find('li.Rest').hide();
        for(const f in content.data) {

            const $cell = $table.find(`td#${f}`).addClass('has-data')
            const $li = $cell.find('li.Rest')
            $li.show();

            const data = content.data[f];

            if (data.short_split || data.long_split || data.full) {
                // Daily Rest
                if (data.full) {
                    $li.html(`<span>${this._format_duration(data.full / 60)}</span>`);
                } else if(data.long_split && data.short_split) {
                    $li.html(`<span>${this._format_duration(data.short_split / 60)} + ${this._format_duration(data.long_split / 60)}</span>`);
                } else if(data.long_split) {
                    $li.html(`<span>${this._format_duration(data.long_split / 60)}</span>`);
                } else if(data.short_split) {
                    $li.html(`<span>${this._format_duration(data.short_split / 60)}`);
                }
            } else {
                // Weekly Rest
                if (data.compensation != 0) {
                    $li.html(
                        `${this._format_duration(data.rest / 60)} (${this._format_duration(data.compensation / 60)}&nbsp;&rarr;)`
                    );
                } else {
                    $li.html(`<span>${this._format_duration(data.rest / 60)}</span>`);
                }
            }

            $cell.off('click').on('click', this.displayDriverActivityTab.bind(this))
        }
        this.calenderRendered($table);
    }

    triggerRemoteRebuildTables(tabName)
    {
        if (!tabName) {
            tabName = this._$hostingTab[0].className.split(' ')[1].substring(4);
        }
        tabName = (tabName.substring(0, 1).toUpperCase() + tabName.substring(1))
            .replace(/-([a-z])/, (m, p1) => p1.toUpperCase());

        super.triggerRemoteRebuildTables(`Driver${tabName}Tab`)
    }
}