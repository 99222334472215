import { DatePickerTab } from './tacho_shared'
import { DateTime } from 'luxon'
import {getSVGForIcon} from "../shared/common";

export default class DriverActivityTab extends DatePickerTab {
    #cumulatives;

    renderContent($table, content) {
        const $tbody = $table.children('tbody')
        $tbody.empty();

        const dates = new Map();
        const startDate = DateTime.fromFormat(this._$startDateField.val(), 'dd-MM-yyyy');
        const endDate = DateTime.fromFormat(this._$endDateField.val(), 'dd-MM-yyyy');
        const days = endDate.diff(startDate, 'days').days;
        for (let i = 0; i <= days; ++i) {
            dates.set(startDate.plus({days: i}).toISODate(), []);
        }

        for (const i in content.data.activities) {
            const row = content.data.activities[i]
            const arrayRef = dates.get(row.activity_change_date);
            arrayRef.push(row);
        }

        if (dates.size > 1) {
            // Copy the first item off every set except the first, and add it to the end of the set for the day before
            // Also copy the last item off every set except the last, and add it to the beginning of the next day
            const prefixItems = [];
            const postfixItems = [];
            for (const [key, set] of dates) {
                prefixItems.push(set[0]);
                postfixItems.push(set[set.length - 1]);
            }
            prefixItems.shift();
            postfixItems.pop();
            if (prefixItems.length > 0) {
                for (const [key, set] of dates) {
                    const row = prefixItems.shift();
                    if (row && set.length > 0) {
                        set.push(row);
                    }
                }
            }

            if (postfixItems.length > 0) {
                const keyList = dates.keys();
                const reverseKeyList = [];
                for (const k of keyList) {
                    reverseKeyList.unshift(k);
                }
                for (const i in reverseKeyList) {
                    const k = reverseKeyList[i];
                    const row = postfixItems.pop();
                    const set = dates.get(k);
                    if (row && set.length > 0) {
                        set.unshift(row);
                    }
                }
            }
        }

        let $td,
            iconHide = '<i class="fad fa-circle-minus"></i>',
            iconExpand = '<i class="fad fa-circle-plus"></i>';

        for (const [date, rows] of dates) {
            const dateObject = DateTime.fromISO(date);
            const $headerRow = $('<tr>');
            const $headerCell = $('<th class="heading">')//.attr('colspan', 2);
            $headerCell.append($('<h3>').text(dateObject.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)))
            $headerRow.append($headerCell);
            $tbody.append($headerRow);

            const $chartRow = $('<tr>').addClass('chart-row');

            $td = $('<td>');

            if (rows.length > 0) {
                const chartUrl = '/admin/img/activity_view/' + this.combineParams([
                    {name: 'driver_id', value: this._$driverIdField.val()},
                    {name: 'date_start', value: dateObject.toISODate()},
                    {name: 'date_end', value: dateObject.plus({days: 1}).toISODate()},
                ]);
                const dialUrl = '/admin/img/activity_dial/' + this.combineParams([
                    {name: 'driver_id', value: this._$driverIdField.val()},
                    {name: 'date_start', value: dateObject.toISODate()},
                    {name: 'date_end', value: dateObject.plus({days: 1}).toISODate()},
                ]);
                const link = `<a class="btn-activity-dial button button-green" href="${dialUrl}" target="_blank">
                    <i class="fa-solid fa-gauge-low"></i>
                    View dial
                </a>`;

                const $chart = $('<object>').attr({
                    'type': 'image/svg+xml',
                    'data': chartUrl
                });

                $td.append($chart);
                $td.html($td.html() + `<br><a class="btn-activity-table-toggle button button-green">${iconExpand} Expand</a>` + link);

                $td.children('a.btn-activity-table-toggle').on('click', e => {

                    const $a = $(e.target);

                    $a.toggleClass('expanded').toggleClass('button-green').toggleClass('button-red');

                    if( $a.hasClass('expanded') ) {
                        $a.html(`${iconHide} Hide`);
                    } else {
                        $a.html(`${iconExpand} Expand`);
                    }

                    let $row = $a.parents('tr');

                    $row.next('tr').fadeToggle('slow');
                });

                $td.find('a.btn-activity-dial').click( e => {
                    e.preventDefault();

                    const $link = $(e.target);
                    window.open($link.attr('href'), 'tacho_dial', 'popup=1,width=640,height=640');
                })

            } else {
                $td = $('<td>').text('No data');
            }

            $chartRow.append($td);

            $tbody.append($chartRow);

            const $activityTable = this.#buildActivityTable(date, rows)

            const $activityRow = $('<tr>').addClass('activity-row').html('<td colspan="4"></td>')
            $activityRow.children('td').append($activityTable);
            $activityRow.hide();
            $tbody.append($activityRow);
        }

        let infringements = content.data.infringements;
        for (const index in infringements) {
            const row = infringements[index];
            const time = DateTime.fromISO(row.date_infringement, { setZone: true });
            const rowID = 'I' + time.toFormat('yyyyMMddHHmmss');
            const $row = $('<tr>').attr('id', rowID).addClass('infringement').addClass(row.infringement_code);
            const $cell = $('<td>').html(`<i class="fas fa-exclamation-triangle"></i> ${row.message}`).attr('colspan', 4);

            $row.append($cell);
            $row.append($('<td>').addClass('comment'));

            const candidateKeys = this._activityRowKeys.filter(this._dropActivityRowKeysAfterDate(time));
            const key = candidateKeys.pop();
            $row.insertAfter('tr#' + key);

            this.insertRowIdIntoActivityRows(rowID)
        }

        const dailyChecks = content.data.daily_checks
        for (const row of dailyChecks) {
            const time = DateTime.fromISO(row.date_start).toUTC();
            const endTime = DateTime.fromISO(row.date_end).toUTC();
            const duration = endTime.diff(time).rescale().toFormat("h'h' m'm'");
            const rowID = 'D' + time.toFormat('yyyyMMddHHmmss');
            const $row = $('<tr>').attr('id', rowID).addClass('daily_check');
            const latLng = ( row.lat ) ? `<a href="https://openstreetmap.org/?mlat=${row.lat}&mlon=${row.lng}#map=17/${row.lat}/${row.lng}" target="_blank" title="View on map"><i class="green fas fa-location"></i></a>` : '';

            let dailyCheckHTML = `
            <td>${time.toLocaleString(DateTime.TIME_SIMPLE)}</td>
            <td><i  title="Daily Check" class="fa-light fa-file-alt"></i>${latLng}</td>
            <td><a href="${app.CACHE.URL_ADMIN}rep/${row.id}">Daily Check #${row.id}</a></td>
            <td>Completed in: ${duration} (Mileage: ${row.mileage})</td>
            <td></td>`;

            $row.html(dailyCheckHTML);

            const candidateKeys = this._activityRowKeys.filter(this._dropActivityRowKeysAfterDate(time));
            const key = candidateKeys.pop();
            $row.insertAfter('tr#' + key);

            this.insertRowIdIntoActivityRows(rowID);
        }

        const $addComment = $('<a>').addClass('btn-remodal btn-remodal-add').attr('data-micromodal-trigger', 'tacho_add_comment').attr('title', 'Add comment').append($('<i>').addClass('btn-comment-add fa-solid fa-comment-plus'));
        const $removeComment = $('<a>').addClass('btn-remodal').attr('data-micromodal-trigger', 'tacho_delete_comment').attr('title', 'Delete comment').append($('<i>').addClass('btn-comment-delete fa fa-trash red'));
        const $editComment = $('<a>').addClass('btn-remodal').attr('data-micromodal-trigger', 'tacho_edit_comment').attr('title', 'Edit comment').append($('<i>').addClass('btn-comment-edit fa-solid blue fa-comment-pen'));

        let comments = content.data.comments;
        const user_id = $('input#user_id').val();
        for (const i in comments) {
            const comment = comments[i];

            const time = DateTime.fromISO(comment.date, { setZone: true });
            const added = DateTime.fromISO(comment.added, {setZone: true}),
              edited = comment.updated !== null ? DateTime.fromISO(comment.updated, {setZone: true}) : null;

            let commentAlt = `Added by ${comment.user} at ${added.toFormat('d-MM-yyyy H:mm')}`;
            if (edited !== null) {
                commentAlt += ` edited at ${edited.toFormat('d-MM-yyyy H:mm')}`
            }

            let row_ref;

            const $row = $('<tr>')
              .addClass('comment')
              .prop('id', 'C' + time.toFormat('yyyyMMddHHmmss'));
            $row.append(
              $('<td>').prop('colspan', 4)
                       .text(comment.comment)
                       .attr('title', commentAlt)
            );

            const $commentColumn = $('<td>').addClass('comment')
            if (comment.user_id == user_id) {
                const $edit = $editComment.clone(true).attr('data-comment-id', comment.id).attr('data-type', comment.type);
                const $remove = $removeComment.clone(true).attr('data-comment-id', comment.id).attr('data-type', comment.type);

                $commentColumn.append($edit, $remove)
            }
            $row.append($commentColumn);

            if (comment.type === 'tco_drv_act_day') {
                const $rowSelection = $('table#activity_' + time.toFormat('yyyy-MM-dd')).find('tr').first();
                $row.insertBefore($rowSelection);
                continue;
            } else if (comment.type === 'tco_drv_anl') {
                row_ref = 'I' + time.toFormat('yyyyMMddHHmmss');
            } else {
                row_ref = time.toFormat('yyyyMMddHHmmss');
            }

            $row.insertAfter('tr#' + row_ref);
        }

        // ADD COMMENTS
        $('table.activity_table').find('tr')
          .filter((i, el) => $(el).attr('id').substring(0, 1) !== 'C')
          .find('td.comment').each( (k,e) => {

              let commentType = 'tco_drv_act';

                if( $(e).closest('tr').hasClass('infringement') ) {
                    commentType = 'tco_drv_anl';
                }

            $(e).append($addComment.clone(true).attr('data-type', commentType));
        });


        // ADD DAY COMMENTS
        for (const dateString of dates.keys()) {
            const date = DateTime.fromISO(dateString);
            const row_id = 'C' + date.toFormat('yyyyMMddHHmmss');
            const $addDayCommentRow = $('<tr>').prop('id', row_id).append(
              $('<td>').attr('colspan', 4),
              $('<td>').append($addComment.clone(true).attr('title', 'Add day comment').attr('data-type', 'tco_drv_act_day'))
            )

            $(`table.activity_table#activity_${date.toFormat('yyyy-MM-dd')} > tr`).filter(':first-of-type').before($addDayCommentRow);
        }

        this.configureModals($('table.activity_table'));
    }


    #buildActivityTable(date, dataBlock) {
        const $activityTable = $('<table>').attr('id', `activity_${date}`).addClass('activity_table')
        this.#cumulatives = {
            'Working': 0,
            'Driving': 0,
            'Rest': 0,
            'Available': 0,
        };

        for (const j in dataBlock) {
            const row = dataBlock[j];
            const eventTime = DateTime.fromISO(row.activity_change_time).toUTC();
            const $tr = $('<tr>').attr('id', eventTime.toFormat('yyyyMMddHHmmss'));

            if (eventTime.toFormat('HHmm') === '0000') {
                // New Day
                this.#cumulatives = {
                    'Working': 0,
                    'Driving': 0,
                    'Rest': 0,
                    'Available': 0,
                };
            } else {
                const [event, details, icons, rowClass] = this.#calculateEvent(row, dataBlock[j - 1], (j == dataBlock.length - 1));
                // time
                let timeText = DateTime.TIME_24_SIMPLE;
                if (j == 0 || j == dataBlock.length - 1) {
                    timeText = {...timeText, ...DateTime.DATE_SHORT};
                }
                $tr.append($('<td>').text(eventTime.toLocaleString(timeText)));
                // icon(s)
                $tr.append($('<td>').append(getSVGForIcon(icons)));
                // event
                $tr.append($('<td>').html(event));
                // details
                $tr.append($('<td>').html(details));
                // comments
                $tr.append($('<td>').addClass('comment'));

                $tr.addClass(rowClass);

                this._activityRowKeys.push(eventTime.toFormat('yyyyMMddHHmmss'));
            }

            $activityTable.append($tr);
        }

        return $activityTable;
    }

    #calculateEvent(row, previousRow, last) {
        let unit_card_slot = row.unit_card_slot,
            driving_status = row.driving_status,
            card_status = row.card_status,
            vehicle_activity = row.vehicle_activity,
            date_activity_change = row.activity_change_time,
            vehicle_id = row.tacho_vehicle_id,
            vehicle_reg = row.reg,
            manual_entry = row.driving_status === 'Known';

        let previous_unit_card_slot,
            previous_driving_status,
            previous_card_status,
            previous_vehicle_activity,
            previous_date_activity_change,
            previous_vehicle_id,
            previous_vehicle_reg,
            previous_manual_entry;

        if (previousRow === undefined) {    // First row, possibly a manual entry from outside the time range
            previous_unit_card_slot = row.unit_card_slot;
            previous_driving_status = row.driving_status;
            previous_card_status = row.card_status;
            previous_vehicle_activity = row.vehicle_activity;
            previous_date_activity_change = row.activity_change_time;
            previous_vehicle_id = row.tacho_vehicle_id;
            previous_vehicle_reg = row.reg;
        } else {
            previous_unit_card_slot = previousRow.unit_card_slot;
            previous_driving_status = previousRow.driving_status;
            previous_card_status = previousRow.card_status;
            previous_vehicle_activity = previousRow.vehicle_activity;
            previous_date_activity_change = previousRow.activity_change_time;
            previous_vehicle_id = previousRow.tacho_vehicle_id;
            previous_vehicle_reg = previousRow.reg;
            previous_manual_entry = previousRow.driving_status === 'Known';
        }

        if (last) {
            const startDate = DateTime.fromISO(previous_date_activity_change);
            const endDate = DateTime.fromISO(date_activity_change);
            const duration = endDate.diff(startDate, ['hours', 'minutes']);

            const icons = [previous_vehicle_activity];
            if (previous_manual_entry) {
                icons.push('ManualEntry');
            }
            return [
                previous_vehicle_activity,
                `${duration.hours}h ${duration.minutes}m`,
                icons,
                ''
            ];
        } else if (previousRow === undefined) {
            // First row - carried status from previous day
            const icons = [vehicle_activity];
            if (manual_entry) {
                icons.push('ManualEntry');
            }
            return [
              vehicle_activity,
              '',// `${duration.hours}h ${duration.minutes}m`,
              icons,
              ''
            ];
        } else if (card_status !== previous_card_status) {
            // Card Insertion/Removal
            const icons = ['Card'];
            icons.push(unit_card_slot === 'Main' ? 'DriverSlot' : 'CodriverSlot');
            if (manual_entry && card_status !== 'Not Inserted') {
                icons.push('ManualEntry');
            }
            if (card_status === 'Inserted') {
                icons.push(vehicle_activity);
                if (vehicle_id != 0) {
                    const vehicleDateView = DateTime.fromISO(date_activity_change);
                    const vehicle_url = `/admin/tco_veh/${vehicle_id}/tab/activity/` + vehicleDateView.toLocaleString(DateTime.DATE_SHORT).replace(/\//g, '-');
                    const vehicle_link = $('<a>').attr({
                        'href': vehicle_url,
                        'target': '_blank'
                    }).addClass('id_reg id_reg_uk').text(vehicle_reg);

                    return [
                        `Card Inserted: set to ${vehicle_activity}`,
                        `${unit_card_slot} slot ` + vehicle_link[0].outerHTML,
                        icons,
                        card_status
                    ];
                } else {
                    return [
                        `Card Inserted: set to ${vehicle_activity}`,
                        `${unit_card_slot} slot`,
                        icons,
                        card_status
                    ];
                }
            } else {
                const startDate = DateTime.fromISO(previous_date_activity_change);
                const endDate = DateTime.fromISO(date_activity_change);
                const duration = endDate.diff(startDate, 'minutes').minutes;
                this.#cumulatives[previous_vehicle_activity] += duration;

                const dailySummary = this.#dailySummary();

                return [
                    'Card Removed',
                    dailySummary,
                    icons,
                    'Removed'
                ];

            }
        }

        const startDate = DateTime.fromISO(previous_date_activity_change);
        const endDate = DateTime.fromISO(date_activity_change);
        const duration = endDate.diff(startDate, 'minutes').minutes;
        this.#cumulatives[previous_vehicle_activity] += duration;

        let activity_description = ($('<span>').text(vehicle_activity))[0].outerHTML;
        const icons = [vehicle_activity];
        if (manual_entry) {
            icons.push('ManualEntry');
        }
        return [
            activity_description,
            `${previous_vehicle_activity}: ${this.#format_duration(this.#cumulatives[previous_vehicle_activity])}`,
            icons,
            vehicle_activity,
        ];
    }

    #dailySummary() {
        return [
            `Driving: ${this.#format_duration(this.#cumulatives.Driving)}`,
            `Working: ${this.#format_duration(this.#cumulatives.Working)}`,
            `Available: ${this.#format_duration(this.#cumulatives.Available)}`,
            `Rest: ${this.#format_duration(this.#cumulatives.Rest)}`,
        ].join(', ');
    }

    #format_duration(minutes) {
        const hours = Number.parseInt(minutes / 60);
        minutes = minutes % 60;
        return `${hours}h ${minutes}m`;
    }

    #dropActivityRowKeysBeforeDate(date)
    {
        if (typeof date === 'string') {
            date = Number.parseInt(date)
        } else {
            date = Number.parseInt(date.toFormat('yyyyMMddHHmmss'))
        }

        return function (value) {
            if (!Number.isInteger(value)) {
                value = a.substring(1)
            }

            value = Number.parseInt(value)
            return value >= date;
        }
    }
}