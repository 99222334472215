import {DatePickerTab} from "./tacho_shared";
import {DateTime} from "luxon";

export default class VehicleInfringementsTab extends DatePickerTab
{
    renderInfringementsTable($table, content) {

        const infringementsTab  = this;
        const $tbody = $table.children('tbody');
        $tbody.empty();

        if (content.data.length === 0) {
            const $row = $('<tr>');
            const $col = $('<td>').html('No Infringements').attr('colspan', 10);
            $row.append($col);
            $tbody.append($row);
        } else {
            for (const index in content.data) {

                const row = content.data[index];
                const $row = $('<tr>');

                $row.data('event-time', moment(row.event_start).format('YYYYMMDDHHmmss'));

                // $row.click(this.jumpToActivityInfringementRow.bind(this))

                for (const k in row) {
                    const data = k === 'event_start' ? moment(row[k]).format(formatDatetime) : row[k]
                    const $column = $(`<td class="col_${k}">`).html(data);
                    $row.append($column);
                }

                $tbody.append($row);
            }

            // attach new click event for button rather than row
            $tbody.find('.btn-view-infringement').on('click', function(e){
                const timestamp = $(e.currentTarget).closest('tr').data('eventTime');
                const dmy = moment(timestamp, 'YYYYMMDDHHmmss').format('DD-MM-YYYY');
                window.location = `${app.CACHE.URL_ADMIN}tco_veh_activity/${app.URI[2]}/${dmy}`;
            });
        }
    }

    jumpToActivityInfringementRow(e) {

        const $activityTab = app.DOM.tabs.filter('.tab-activity');
        const $activityTabHeader = app.DOM.form_tab_a.filter('.tab-activity');
        const $clickedRow = $(e.target).closest('tr');
        const $tab = $clickedRow.parents('.tab');
        const targetRow = 'I' + $clickedRow.data('event-time');

        if ($tab[0] === $activityTab[0]) {
            document.getElementById(targetRow).scrollIntoView();
            window.scrollBy(0, -75);
        } else {

            const date = DateTime.fromFormat($clickedRow.data('event-time'), 'yyyyMMddHHmmss').toFormat('dd-MM-yyyy');

            $activityTab.find('.startDatePicker').val(date);
            $activityTab.find('.startDatePicker').datepicker('setDate', date);
            $activityTab.find('.endDatePicker').val(date);
            $activityTab.find('.endDatePicker').datepicker('setDate', date);
            $activityTab.find('#date_start').val(date);
            $activityTab.find('#date_end').val(date);

            $activityTabHeader.trigger('click');

            window.setTimeout(function () {
                document.getElementById(targetRow).scrollIntoView();
                window.scrollBy(0, -75);
            }, 500);

            this.updateUrlFromClickedTab();
        }

        e.stopPropagation();
    }
}