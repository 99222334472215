import {DatePickerTab} from "./tacho_shared";
import {DateTime} from "luxon";

export default class DriverLettersTab extends DatePickerTab
{
    renderLettersTable($table, content)
    {
        const lettersTab = this;
        const $tbody = $table.children('tbody')
        $tbody.empty();


        if (content.data.length === 0) {
            const $row = $('<tr>');
            const $col = $('<td>').html('No Letters').attr('colspan', 10);
            $row.append($col);
            $tbody.append($row);
        } else {
            for(const index in content.data) {
                const row = content.data[index];
                const $row = $('<tr>');

                for (const k in row) {

                    const $column = $(`<td class="col_${k}">`).html(row[k]);
                    $row.append($column);
                }

                $tbody.append($row);
            }

            // Letter Info
            $tbody.find('.btn-letter-info').on('click', function() {
                lettersTab.letterInfo($(this));
            });
        }
    }

    letterInfo($btn)
    {
        MicroModal.show('modal-letter-info', {
            onShow: function(modal){

                $(modal).find('.modal__content').html(
                    `<iframe src="${$btn.attr('data-href')}" width="100%" height="500"></iframe>`
                );
            }
        });
    }
}