import { DateTime, Settings } from 'luxon'
import '../../js/plugins/multiSelect'
import 'jquery-ui'
import LibraryFunctions from './_library_functions'
const MicroModal = require('../../js/plugins/micromodal');
require('../../../node_modules/@aggul/jquery.serializeobject/jquery.serializeObject');
import { extend } from 'lodash'
import {AjaxPromise, AjaxSync} from "../shared/ajax_utilities";
import {dateConvert} from "../shared/text_utilities";
import {displayErrors, manifest} from "../shared/common";
import {Toast} from "../shared/toast";

export class BaseTab extends LibraryFunctions {

    // public

    // protected (convention)
    _$hostingTab;
    _$driverIdField;
    _$vehicleIdField;
    _activityRowKeys = [];

    // private (enforced)
    #$loading = $(`<tr class="loading"><td colspan="50" class="ac"><img src="${manifest('img/loading-black.svg')}" width="30"></td></tr>`);

    constructor($tab) {
        super();
        this._$hostingTab = $tab;
        this._$driverIdField = $('input#driver_id');
        this._$vehicleIdField = $('input#vehicle_id');

        const multiSelects = this._$hostingTab.find('select[multiple]')

        if (multiSelects.length > 0) {
            multiSelects.multiselect({
                columns: 1,
                search: true,
                selectAll: true,
                minHeight: 150,
                onOptionClick: function(e){

                    const $el = $(e.element).next();
                    const count = $el.find(':checked').not('[value="0"]').length;

                    // update text
                    $el.closest('.multiselect-container').find('.multiple_count').text(count);
                }
            }).on('change', () => {
                this.rebuildTables();
            });
        }
    }

    triggerRemoteRebuildTables(callback) {
        this.rebuildTables(callback);
    }

    rebuildTables(callback) {

        const $ajaxTables = this._$hostingTab.find('table').filter((index, table) => {
            const $table = $(table);
            var baseUrl = $table.data('source-url');

            return (baseUrl !== undefined);
        });

        $ajaxTables.each((index, table) => {
            const $table = $(table);
            this.loadContent($table, callback);
        })
    }

    loadContent($table, callback) {

        const baseUrl = $table.data('source-url');
        if (!baseUrl) {
            return;
        }

        this._$hostingTab.find('div.filter').find('input[name$="_date"]').each((index, e) => {
            if (e.value === '') {
                const dateStr = $(e).datepicker('option', 'maxDate')
                const date = DateTime.fromJSDate(dateStr);
                e.value = date.toFormat('dd-MM-yyyy');
            }
        })
        const params = this._$hostingTab.find('div.filter').find('input,select,textarea').serializeArray();

        params.push({
            "name": "driver_id",
            "value": this._$driverIdField.val(),
        });
        params.push({
            "name": "vehicle_id",
            "value": this._$vehicleIdField.val(),
        });
        const url = baseUrl + '/' + this.combineParams(params)

        const $tbody = $table.children('tbody');
        $tbody.empty();
        $tbody.append(this.#$loading.clone());

        const _this = this;

        const defaultRenderer = function () {
            return function (response) {
                _this.renderContent($table, response);
            }
        };

        let renderingFunction, renderingFunctionName = $table.data('mapper-function');
        if (renderingFunctionName === undefined) {
            renderingFunction = defaultRenderer;
        } else {
            renderingFunction = function () {
                const f = _this[renderingFunctionName];
                if (typeof f === 'function') {
                    return function (response) {
                        f.apply(_this, [$table, response]);
                    }
                }
            }
        }

        let renderingFunctionList = [renderingFunction()];
        if (typeof callback === 'function') {
            renderingFunctionList.push(callback);
        }

        AjaxSync({ url, method: 'GET' }, { done: renderingFunctionList });
    }

    combineParams(paramArray) {
        const params = [], returnParams = [];
        for (const o of paramArray) {
            if (o.name.endsWith('[]')) {
                o.name = o.name.slice(0, -2);
            }

            if (!params[o.name]) {
                params[o.name] = [];
            }

            params[o.name].push(o.value);
        }

        for (const k in params) {
            if (k.endsWith('_original')) {
                continue;
            }

            const v = params[k];
            returnParams.push(`${k}:${v.join(',')}`)
        }

        return returnParams.join('|');
    }

    renderContent($table, content) {
        const $tbody = $table.children('tbody')
        $tbody.empty();

        for (const index in content.data) {
            const row = content.data[index];
            const $row = $('<tr>');

            for (const k in row) {
                const $column = $('<td>').text(row[k]);
                $row.append($column);
            }

            $tbody.append($row);
        }
    }
}

export class DatePickerTab extends BaseTab {
    _$startDateField;
    _$endDateField;
    _lowerLimit;
    _upperLimit;

    #resetDatesButton;
    #$prevMonthButton;
    #$nextMonthButton;

    _$buttons

    constructor($tab) {
        super($tab);

        this._upperLimit = DateTime.fromSQL($('input#latest_data_raw').val());
        this._lowerLimit = DateTime.fromSQL($('input#earliest_data_raw').val());
        const lowerLimit = DateTime.now().minus({months: 18})
        if (this._lowerLimit < lowerLimit) {
            this._lowerLimit = lowerLimit
        }

        this.loadDatePickers();
    }


    loadDatePickers() {

        const baseOptions = {
            'firstDay': 1,      // ISO Weeks always start on Monday
            'dateFormat': 'dd-mm-yy',
            'changeYear': true,
            'changeMonth': true,
            'weekHeader': 'W',
            'minDate': this._lowerLimit.toJSDate(),
            'maxDate': this._upperLimit.toJSDate(),
        }


        this.#$prevMonthButton = this._$hostingTab.find('a.calendar_prev_month');
        this.#$nextMonthButton = this._$hostingTab.find('a.calendar_next_month');

        this._$startDateField = this._$hostingTab.find('.startDatePicker').addClass('datepicker').after('<i class="fa fa-calendar-alt datepicker-cal "></i>');
        this._$endDateField = this._$hostingTab.find('.endDatePicker').addClass('datepicker').after('<i class="fa fa-calendar-alt datepicker-cal "></i>');

        this._$startDateField.datepicker(extend({
            'defaultDate': this._$startDateField.val(),
            // 'maxDate': this._$endDateField.val(),
            'onSelect': this.updateStartDate.bind(this),
        }, baseOptions));

        this._$endDateField.datepicker(extend({
            'defaultDate': this._$endDateField.val(),
            'minDate': this._$startDateField.val(),
            'onSelect': this.updateEndDate.bind(this),
        }, baseOptions));

        this.#resetDatesButton = this._$hostingTab.find('button.button-reset');
        this.#resetDatesButton.click((e) => {
            Settings.throwOnInvalid = true
            let startDate
            try {
                startDate = DateTime.fromISO(this._$startDateField.data('default'))
            } catch (e) {
                startDate = this._lowerLimit
            }

            let endDate
            try {
                endDate = DateTime.fromISO(this._$endDateField.data('default'))
            } catch (e) {
                endDate = this._upperLimit
            }

            this._$startDateField.datepicker('setDate', startDate.toJSDate());
            this._$endDateField.datepicker('setDate', endDate.toJSDate());
            this.rebuildTables(this.updateUrlFromClickedTab.bind(this));

            e.preventDefault();
        })

        this.#$prevMonthButton.click((e) => {
            e.preventDefault();

            const startOfRange = DateTime.fromFormat(this._$startDateField.val(), 'd-MM-yyyy');
            const endOfRange = DateTime.fromFormat(this._$endDateField.val(), 'd-MM-yyyy');

            if (startOfRange.equals(endOfRange)) {
                const newDate = startOfRange.minus({days: 1});
                if (newDate >= this._lowerLimit && !newDate.equals(startOfRange)) {
                    this.#updateDateRange(newDate, newDate);
                }
                return;
            }

            const diff = endOfRange.diff(startOfRange, 'days').get('days') + 1;
            let newDateStart = startOfRange.minus({days: diff});
            let newDateEnd = endOfRange.minus({days: diff});

            if (newDateStart < this._lowerLimit) {
                newDateStart = this._lowerLimit;
                newDateEnd = newDateStart.plus({days: diff - 1});
            }
            if (!newDateStart.equals(startOfRange) && !newDateEnd.equals(endOfRange)) {
                this.#updateDateRange(newDateStart, newDateEnd);
            }
        })

        this.#$nextMonthButton.click((e) => {
            e.preventDefault();

            const startOfRange = DateTime.fromFormat(this._$startDateField.val(), 'd-MM-yyyy');
            const endOfRange = DateTime.fromFormat(this._$endDateField.val(), 'd-MM-yyyy');

            if (startOfRange.equals(endOfRange)) {
                const newDate = startOfRange.plus({days: 1});
                if (newDate <= this._upperLimit && !newDate.equals(endOfRange)) {
                    this.#updateDateRange(newDate, newDate);
                }
                return;
            }

            const diff = endOfRange.diff(startOfRange, 'days').get('days') + 1;
            let newDateStart = startOfRange.plus({days: diff});
            let newDateEnd = endOfRange.plus({days: diff});
            if (newDateEnd > this._upperLimit) {
                newDateEnd = this._upperLimit;
                newDateStart = newDateEnd.minus({days: diff - 1});
            }
            if (!newDateStart.equals(startOfRange) && !newDateEnd.equals(endOfRange)) {
                this.#updateDateRange(newDateStart, newDateEnd);
            }
        })
    }

    #updateDateRange(newStartDate, newEndDate) {
        this._$startDateField.datepicker('option', 'maxDate', newEndDate.toJSDate());
        this._$startDateField.datepicker('setDate', newStartDate.toJSDate());
        this._$endDateField.datepicker('option', 'minDate', newStartDate.toJSDate());
        this._$endDateField.datepicker('setDate', newEndDate.toJSDate());
        this.rebuildTables(this.updateUrlFromClickedTab.bind(this));
    }

    updateStartDate(dateString) {
        const startDate = DateTime.fromFormat(dateString, 'dd-MM-yyyy');

        // let endDate = DateTime.fromFormat(this._$endDateField.val(), 'dd-MM-yyyy');

        let endDate = startDate;

        this._$endDateField.datepicker('option', 'minDate', startDate.toJSDate());
        this._$endDateField.datepicker('setDate', endDate.toJSDate());

        this.rebuildTables(this.updateUrlFromClickedTab.bind(this));
    }

    updateEndDate(dateString) {

        const endDate = DateTime.fromFormat(dateString, 'dd-MM-yyyy');
        let startDate = DateTime.fromFormat(this._$startDateField.val(), 'dd-MM-yyyy');

        this._$startDateField.datepicker('option', 'maxDate', dateConvert(this._$endDateField.attr('data-default')));

        this._$startDateField.datepicker('setDate', startDate.toJSDate());

        this.rebuildTables(this.updateUrlFromClickedTab.bind(this));
    }

    updateUrlFromClickedTab() {
        const dateFilterValue = this.getNewDateFilterValue();

        const clickedTab = app.FORM.getTabKey(this._$hostingTab);
        if (app.URI[4] !== clickedTab) {
            app.URI[4] = clickedTab;
        }
        if (app.URI.length >= 5) {
            app.URI[5] = dateFilterValue;

            window.history.replaceState(null, null, '/' + app.URI.join('/'));
        }
    }

    getNewDateFilterValue() {
        const startDateValue = this._$startDateField.val()
        const endDateValue = this._$endDateField.val();

        if (startDateValue === endDateValue) {
            return startDateValue;
        }

        return `${startDateValue}:${endDateValue}`;
    }

    rebuildTables(callback)
    {
        super.rebuildTables(callback);

        const rangeStart = DateTime.fromFormat(this._$startDateField.val(), 'dd-MM-yyyy');
        const rangeEnd = DateTime.fromFormat(this._$endDateField.val(), 'dd-MM-yyyy');

        if (rangeStart <= this._lowerLimit) {
            this.#$prevMonthButton.addClass('disabled-next-prev');
        } else {
            this.#$prevMonthButton.removeClass('disabled-next-prev');
        }

        if (rangeEnd >= this._upperLimit) {
            this.#$nextMonthButton.addClass('disabled-next-prev');
        } else {
            this.#$nextMonthButton.removeClass('disabled-next-prev');
        }
    }

    _dropActivityRowKeysAfterDate (date) {
        if (typeof date === 'string') {
            date = Number.parseInt(date)
        } else {
            date = Number.parseInt(date.toFormat('yyyyMMddHHmmss'))
        }

        return function (value) {
            if (isNaN(Number.parseInt(value))) {
                value = value.substring(1)
            }

            value = Number.parseInt(value)

            return value <= date
        }
    }

    _dropActivityRowKeysBeforeDate (date) {
        if (typeof date === 'string') {
            date = Number.parseInt(date)
        } else {
            date = Number.parseInt(date.toFormat('yyyyMMddHHmmss'))
        }

        return function (value) {
            if (isNaN(Number.parseInt(value))) {
                value = value.substring(1)
            }

            value = Number.parseInt(value)

            return value > date
        }
    }

    insertRowIdIntoActivityRows (rowID) {
        this._activityRowKeys.push(rowID)
        this._activityRowKeys.sort((a, b) => {
            let aValue, bValue
            if (!Number.isInteger(a)) {
                aValue = Number.parseInt(a.substring(1))
            } else {
                aValue = Number.parseInt(a)
            }
            if (!Number.isInteger(b)) {
                bValue = Number.parseInt(b.substring(1))
            } else {
                bValue = Number.parseInt(b)
            }

            return aValue - bValue
        })
    }

    configureModals ($ownerTable) {
        this.$buttons = $ownerTable.find('.btn-remodal')
        this.$buttons.filter('.btn-remodal').off('click')

        this.$buttons.filter('.btn-remodal').click((e) => {
            e.preventDefault()
            let $el = $(e.target)
            if ($el[0].tagName !== 'a') {
                $el = $el.parents('a')
            }
            $el.addClass('remodal-active')

            const modalType = $el.data('micromodalTrigger')

            this._configureCommentModal(modalType, $el)
            MicroModal.show(modalType, {
                onClose: (modal, activeElement, event) => this._handleCloseCommentModal(modal, activeElement, event, $el)
            })
        })
    }

    _configureCommentModal (modalType, $button) {
        const $modal = $('div#' + modalType)
        let modalTypeName

        switch ($button.attr('data-type')) {
            case 'tco_drv_anl':
                modalTypeName = 'Infringement'
                break
            case 'tco_drv_act_day':
                modalTypeName = 'Day'
                break
            case 'tco_drv_act':
                modalTypeName = 'Activity'
                break
            default:
                modalTypeName = $button.attr('data-type')
                break
        }


        $modal.find('h1 span').text(modalTypeName)

        if (modalType === 'tacho_add_comment') {

            // clear any existing input
            $modal.find('#text').val('')

            let key = $button.parents('tr').attr('id')
            const type = key.substring(0, 1)

            if (type === 'I') {
                $modal.find('#tbl').val('tco_drv_anl')
                key = key.substring(1)
            } else if (type === 'C') {
                $modal.find('#tbl').val('tco_drv_act_day')
                key = key.substring(1)
            } else {
                $modal.find('#tbl').val('tco_drv_act')
            }

            $modal.find('#event_key').val(key)
        }

        if (modalType === 'tacho_edit_comment') {
            $modal.find('#text').val($button.parent().prev('td').text())
            $modal.find('#comment_id').val($button.data('comment-id'))
        }

        if (modalType === 'tacho_delete_comment') {
            $modal.find('#comment_id').val($button.data('comment-id'))
        }

        if (modalType === 'tacho_mileage_add_comment') {
            $modal.find('#gap_id').val($button.data('gap-id'))
        }

        if (modalType === 'tacho_mileage_edit_comment') {
            const comment_id = $button.data('comment-id');
            $modal.find('#text').val($(`span#comment_${comment_id}`).text())
            $modal.find('#comment_id').val(comment_id)
        }

        if (modalType === 'tacho_mileage_delete_comment') {
            $modal.find('#comment_id').val($button.data('comment-id'))
        }
    }

    _handleCloseCommentModal (modal, activeElement, event, $sourceElement) {
        const modalType = modal.id,
          $modal = $(modal),
          triggerElement = event.target

        if (triggerElement.tagName !== 'BUTTON' || !$(triggerElement).hasClass('remodal-confirm')) {
            return
        }

        const data = $modal.find('input,select,textarea').serializeArray()

        let targetRowID

        let targetURL
        const driver_callback = () => {
            const $targetRow = $(`#${targetRowID}`)
            $targetRow.closest('table.activity_table').closest('tr').prev().find('a.btn-activity-table-toggle').click()
            $targetRow[0].scrollIntoView()
            $targetRow[0].scrollBy({ top: 100 })
        }
        const vehicle_callback = () => {}
        let callback

        if (modalType === 'tacho_add_comment') {
            targetURL = app.CACHE.URL_AJAX + 'tacho_drv/get/add_comment'
            targetRowID = $modal.find('#event_key').val()
            switch ($modal.find('#tbl').val()) {
                case 'tco_drv_anl':
                    targetRowID = 'I' + targetRowID
                    break
                case 'tco_drv_act_day':
                    targetRowID = 'C' + targetRowID
                    break
            }

            callback = driver_callback;
        }

        if (modalType === 'tacho_edit_comment') {
            targetURL = app.CACHE.URL_AJAX + 'tacho_drv/get/edit_comment'
            targetRowID = $sourceElement
              .first()
              .closest('tr')
              .attr('id')
            callback = driver_callback;
        }

        if (modalType === 'tacho_delete_comment') {
            targetURL = app.CACHE.URL_AJAX + 'tacho_drv/get/delete_comment'
            targetRowID = $sourceElement
              .first()
              .closest('tr')
              .prev()
              .attr('id')
            callback = driver_callback;
        }

        if (modalType === 'tacho_mileage_add_comment') {
            targetURL = app.CACHE.URL_AJAX + 'tacho_missing_mileage_comment_add/'
            callback = vehicle_callback;
        }

        if (modalType === 'tacho_mileage_edit_comment') {
            targetURL = app.CACHE.URL_AJAX + 'tacho_missing_mileage_comment_update/'
            callback = vehicle_callback;
        }

        if (modalType === 'tacho_mileage_delete_comment') {
            targetURL = app.CACHE.URL_AJAX + 'tacho_missing_mileage_comment_delete/'
            callback = vehicle_callback;
        }

        AjaxSync({
            url: targetURL,
            data,
            method: 'POST'
        }, {
            done: (res) => {
                this.rebuildTables(callback)
            }
        });
    }
}

export function addLetterModal(view, $el, tacho_driver_id, type, date_range, driver_name, driver_email)
{
    // do nothing if already in ajax state
    if( $el.hasClass('ajax') ) {
        return;
    }

    const date_range_formatted = ( $.isArray(date_range) ) ? `between ${date_range.join(' - ')}` : `on ${dateConvert(date_range)}`;

    app.DOM.content.find('#letter-info').text(`Showing infringements for ${driver_name} ${date_range_formatted}`);

    if( driver_email ) {
        app.DOM.content.find('#email').val(driver_email);
    }

    MicroModal.show('modal-letter', {
        onShow: function(modal){
            $(modal).find('#action').val('na');
            
            // setup events for once
            $(modal).find('input[name="sent"]').unbind().on('change', function(){

                if( this.value === 'email' ) {
                    $(modal).find('input[name="email"]').removeClass('disabled').prop('disabled', false).focus();
                } else {
                    $(modal).find('input[name="email"]').addClass('disabled').prop('disabled', true);
                }
            });

            // setup events for once
            $(modal).find('button[data-action="ok"]').unbind().on('click', async () => {

                $el.addClass('ajax');

                let data = $.extend({
                    tacho_driver_id: tacho_driver_id,
                    type: type,
                    date_range: date_range,
                  },
                  $(modal).find('form').serializeObject()
                );

                try {
                    const res = await AjaxPromise({
                        url: `${app.CACHE.URL_AJAX}tacho_infringement_letters/add`,
                        method: 'POST',
                        data: data
                    });

                    if( $.isNumeric(res.data) ) {
                        Toast.success(`Successfully sent infringement letter to driver via ${data.sent}`);
                        const downloadUrl = `${app.CACHE.URL_ADMIN}tco_drv_let/${res.data}`;

                        if( view === 'activity' ) {
                            app.DOM.content.find('#create_infringement_letter').hide();
                            app.DOM.content.find('#view_infringement_letter').show().attr('href', `${app.CACHE.URL_ADMIN}tco_drv_let/${res.data}`);
                        } else if( view === 'list_tacho_infringements' ) {
                            app.DOM.table.find(`tr[data="${tacho_driver_id}"]`).addClass('fadeOut').fadeOut('slow', 'swing');
                            app.DOM.content.find('#supervisor_comments').val(''); // clear value in textarea on list
                        }

                        // redirect user to view letter
                        if( data.display_letter === '1' )  {
                            window.location = downloadUrl;
                        } else {
                            $el.closest('td').find('.btn-letter-download').attr('href', downloadUrl).show();
                        }

                    } else {

                        // change error state
                        $el.removeClass('ajax');
                        // show errors
                        displayErrors(res);
                    }
                } catch {}
            });
        }
    });
}