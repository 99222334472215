// import $ from 'jquery';
import 'jquery-ui'
import instantiator from '../_instantiator'
import { map } from 'lodash'

require('../../js/plugins/multiSelect')

$(() => {
  window.$tabs = new Map(map(app.DOM.tabs, (t) => {
    const tabClass = '.' + t.classList[1],
      jsClassName = tabToClassName(tabClass)

    return [jsClassName, instantiator(jsClassName, $(`div${tabClass}`))]
  }))

  if (app.URI[4]) {
    $(`a.tab-${app.URI[4]}`).click()
    const tabObject = tabToClassName('.tab-' + app.URI[4]);

    window.$tabs.get(tabObject)?.triggerRemoteRebuildTables()
  }

  app.DOM.tabs.filter('.tab-dates').find(':input').addClass('disabled').prop('disabled', true);
})